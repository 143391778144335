








































import { FormField } from '@/app_code/Forms'
import { Modal } from '@/app_code/Modals/Modal'
import FileUploadField from '@/components/UI/Elements/Forms/FileUploadField.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { Config } from 'truemarket-modules/src/environment'
import GetPracticeModel from 'truemarket-modules/src/models/api/practices/GetPracticeModel'
import GetValuerProfileModel from 'truemarket-modules/src/models/api/valuations/GetValuerProfileModel'
import UpdateValuerProfileModel from 'truemarket-modules/src/models/api/valuations/UpdateValuerProfileModel'
import { Services } from 'truemarket-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    FileUploadField
  }
})
export default class ValuerProfileIndex extends Vue {
  private practice: GetPracticeModel | null = null

  private fields: FormField[] = []
  private profile: GetValuerProfileModel | null = null

  private needsCreating = false

  private replacementProfilePicture: string | null = null
  private replacementSignature: string | null = null

  private apiBase = Config.API.Base

  async mounted () {
    this.practice = (await Services.API.Users.GetPractices())[0]

    try {
      this.profile = await Services.API.Practices.GetMeInPractice(this.practice.PracticeId)

      this.fields = [
        new FormField({ Key: 'Position', Type: 'text', Placeholder: 'E.g. Principal Valuer', Label: 'Position', Value: this.profile?.Position }),
        new FormField({ Key: 'Accreditation', Type: 'text', Placeholder: 'E.g. AAPI/CPV Valuer No. 1234', Label: 'Accreditation', Value: this.profile?.Accreditation })
      ]
    } catch (ex) {
      this.needsCreating = true

      this.fields = [
        new FormField({ Key: 'Position', Type: 'text', Placeholder: 'E.g. Principal Valuer', Label: 'Position' }),
        new FormField({ Key: 'Accreditation', Type: 'text', Placeholder: 'E.g. AAPI/CPV Valuer No. 1234', Label: 'Accreditation' })
      ]
    }
  }

  async save (): Promise<void> {
    if (!this.practice) return

    const model = (this.$refs.form as Form).GetValues().BuildModel<UpdateValuerProfileModel>()

    model.ProfilePictureFileId = this.replacementProfilePicture ?? this.profile?.ProfilePicture?.FileId ?? null
    model.SignatureFileId = this.replacementSignature ?? this.profile?.Signature?.FileId ?? null

    this.profile = await Services.API.Practices.SetMeInPractice(this.practice.PracticeId, model)
  }

  async uploadFile (slot: 'ProfilePictureId' | 'SignatureId'): Promise<void> {
    const modal = new Modal({
      Heading: 'Upload File',
      FormFields: [
        new FormField({
          Type: 'userfile',
          Key: 'File',
          Label: 'File',
          OnGotValue: () => {
            modal.GetFormValues().then((ds) => {
              const fileField = ds.Fields.find((f) => f.Key === 'File')

              if (slot === 'ProfilePictureId') {
                this.replacementProfilePicture = fileField?.Value as string
              } else if (slot === 'SignatureId') {
                this.replacementSignature = fileField?.Value as string
              }

              modal.Hide()

              this.save()
            })
          }
        })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            modal.Hide()
          }
        }
      ]
    })

    modal.Show()
  }
}
